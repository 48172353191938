import { FleamarketArticleStatus } from "__generated__/jampot/alpha-ca";
import { Box } from "@daangn/carotene";
import clsx from "clsx";
import { match } from "ts-pattern";

import { useLocale } from "../remix-lib";
import Image from "../services/Image";
import * as css from "./SquareThumbnailCard.css";

type SquareThumbnailCardProps = {
  image: string;
  title: string;
  subTitle: string;
  regionName: string;
  status?: FleamarketArticleStatus;
  theme?: "base" | "homePage";
  flex1?: boolean;
};

export default function SquareThumbnailCard({
  image,
  title,
  subTitle,
  regionName,
  status = FleamarketArticleStatus.Ongoing,
  theme = "base",
  flex1,
}: SquareThumbnailCardProps) {
  const { t } = useLocale();
  return (
    <article
      className={clsx(css.container({ theme, flex1 }), css.cardSelector)}
    >
      <Box position="relative">
        <div className={css.imageSkeleton}>
          <Image
            wrapperClassName={css.imageWrapper}
            imgClassName={css.image}
            src={image}
            alt="thumbnail"
          />
        </div>

        {match(status)
          .with(FleamarketArticleStatus.Reserved, () => (
            <span className={css.badge({ status })}>
              {t("SquareThumbnailCard.reserved_badge_label")}
            </span>
          ))
          .with(FleamarketArticleStatus.Closed, () => (
            <span className={css.badge({ status })}>
              {t("SquareThumbnailCard.closed_badge_label")}
            </span>
          ))
          .otherwise(() => null)}
      </Box>

      <div className={css.information}>
        <div className={css.mainInfo}>
          <h2 className={css.title}>{title}</h2>
          <div className={css.subTitle}>{subTitle}</div>
        </div>
        <div className={css.regionContainer}>
          <div className={css.region}>{regionName}</div>
        </div>
      </div>
    </article>
  );
}
